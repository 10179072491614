import React from 'react'
import rectangle_img from "../Images/rectangleShape.svg"
const SquareImg = () => {
  return (
    <div className='shape_img_wrapper'>
    <img src={rectangle_img} alt='rectangle_img'/>
    </div>
  )
}

export default SquareImg
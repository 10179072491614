import React from 'react'

import square_img from "../Images/shape.svg"
const CommonRectangleImg = () => {
  return (
    <div className='rectangle_img_wrapper'>
      <img src={square_img} alt='square_img'/>
      </div>
  )
}

export default CommonRectangleImg